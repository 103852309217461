

body { 
  font-family: "Helvetica Neue", Helvetica,Arial, sans-serif; color:black;
   font-size:  calc(0.6em - 0.6vh);
   background: rgb(11,0,36);
   background: linear-gradient(90deg, rgba(11,0,36,1) 0%, rgba(76,9,121,0.5) 35%, rgba(0,212,255,0.5) 100%);
}
.i {
  height: 14px;
  margin: 0 10px;
}
.nav-black{
  color: black;
}
.navLinkColor
  {
    cursor: pointer;
    height: 14px;
    margin: 0 25px;
    color: white;
  }
.navLinkBell
  {
    cursor: pointer;
    height: 14px;
    color: white;
  }
  .navbar-brand
  {
    margin-left: 15px;
  }
  .right-align{
    float: right;
    margin-right: 15px;
  }
#wrapper {
  overflow-x: hidden;
}


#page-content-wrapper {
  min-width: 100vw;
}



.d-flex {
  display: flex !important;
}
.page-popup{
  position:absolute; 
  z-index:30;
  display:none;
  padding: 10px; 
  width:95%;
  top: 70px;
  overflow-y: auto;

  /*-moz-border-radius: 5px; -webkit-border-radius: 5px; */
 }
 .page-popup-overlay {
     position:fixed;
  z-index:20;
 
     /* color with alpha channel */
     background-color: rgba(0, 0, 0, 0.8); /* 0.7 = 70% opacity */
     
  width:100%;
     /* stretch to screen edges */
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
 }
 .row{ padding: 5px; }
 .btn { margin: 3px; font-size: 6px; margin-left: 25%; margin-right: 25% }
.col-lg-4{margin-bottom: 5px;}

.navbar
{
    
 /*   background-color: rgba(0,0,0,0.5); 
 background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,1));*/
  background: -webkit-linear-gradient(to right, #7579ff, #b224ef);
  background: -o-linear-gradient(to right, #7579ff, #b224ef);
  background: -moz-linear-gradient(to right, #7579ff, #b224ef);
  background: linear-gradient(to right, #7579ff, #b224ef);
    border-radius:4px;
    color:#FFF;
    margin: 2px;
}
.link{
  cursor: pointer;
  color: #337ab7;
  font-size: xx-large;
}
.link:hover{
  cursor: pointer;
  color: #7579ff
}

.link-red{
  cursor: pointer;
  color: #d10c0c;
  margin-right: 5px;
}
.link-red:hover{
  cursor: pointer;
  color: #b41e1e
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 30%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader-overlay {
    position:fixed;
     z-index:80;

    /* color with alpha channel */
    background-color: rgba(0, 0, 0, 0.7); /* 0.7 = 70% opacity */
    
 width:100%;
    /* stretch to screen edges */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.card-green{border-color:#bcdba3; margin-bottom: 10px}.card-green>.card-header{color:#000;background-color:#b3ffb3;border-color:#99ccff}.card-green>.card-body{background-color:#eff7ef;}
.card-blue{border-color:#80bfff; margin-bottom: 10px}.card-blue>.card-header{color:#000;background-color:#99ccff;border-color:#99ccff}.card-blue>.card-body{border-top-color:#cde3f8}
.card-red{border-color:#d10c0c; margin-bottom: 10px}.card-red>.card-header{color:#000;background-color:#e64343;border-color:#d10c0c}.card-red>.card-header+.card-collapse>.card-body{border-top-color:#d10c0c}
.border-success{margin-bottom: 10px}
@media screen and (min-width: 536px) {
  #card {
  
  width:500px; margin:50px auto 
  }
  }

  .user-form{
    padding: 5px 0;
  }
  
  .user-form #form{
    background-color: rgba(255,255,255,1.0);
      max-width: 600px;
      margin: auto;
      padding: 50px 30px;
      border-radius: 30px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  }
  
   
  
  @media (max-width: 576px) {
    * {font-size: 12px;}
    body{
       font-size: 00.875rem;
    }
      .registration-form form{
          padding: 5px 10px;
      }
      .user-form #form{
          padding: 20px 20px;
      }
      .registration-form .form-icon{
          width: 70px;
          height: 70px;
          font-size: 30px;
          line-height: 70px;
      }
  }
  #viewerContainer{
    position: absolute;
overflow: auto;
width: 100%;
top: -100rem;
bottom: 1rem;
left: 0;
right: 0;
  }
  .pdfViewer{
    z-index: 1
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
}
.link-main{
  color:#FFF;
  cursor: pointer; 
  font-size: larger;
}
.link-main:hover {
  color:brown;
}

.progress-loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  border-bottom: 16px solid blue;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
.longWidth{
  width: 92%;
}
.shortWidth{
  width: 4%;
}
@media only screen and (max-width: 986px) {
  /* For mobile phones: */
  .longWidth{
    width: 80%;
  }
  .shortWidth{
    width: 10%;
  }
}